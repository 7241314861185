import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from "semantic-ui-react";
import PageDescription from "./PageDescription";
import NewGame from "./NewGame";
import LoadGame from "./LoadGame";
import SiteHeader from "../common/SiteHeader";
import RecentGames from "./RecentGames";

const HomePage = ({history}) => {
  return (
    <React.Fragment>
      <SiteHeader/>
      <PageDescription/>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <NewGame history={history}/>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <LoadGame history={history}/>
        </Grid.Column>
      </Grid>
      <RecentGames/>
    </React.Fragment>
  );
};

HomePage.propTypes = {
  history: PropTypes.object.isRequired
};

export default HomePage;
