import _ from "lodash";
import {Icon} from "semantic-ui-react";
import React from "react";

export function renderPlayerThs(players, playerTotalScore) {
  const sortedPlayers = _.orderBy(players, ['id']);
  const orderedScores = _.orderBy(playerTotalScore, ['points'], ['desc']);
  return sortedPlayers.map(p => {
    const playerScore = _.find(playerTotalScore, {playerId: p.id}).points;
    let icon = "";
    if (playerScore === orderedScores[0].points) {
      icon = <Icon name="trophy" color="yellow"/>
    } else if (playerScore === orderedScores[1].points) {
      icon = <Icon name="trophy" color="grey"/>
    } else if (playerScore === orderedScores[2].points) {
      icon = <Icon name="trophy" color="brown"/>
    } else {
      icon = <Icon name="frown outline"/>
    }

    return <th key={`player-stat-header-${p.id}`}>{icon} {p.name}</th>
  });
}
