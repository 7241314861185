import React from 'react';
import {Confirm} from "semantic-ui-react";
import {texts} from "../../../config/texts";
import PropTypes from "prop-types";
import {fieldValue, firestore} from "../../../config/firebaseConfig";
import {config} from "../../../config/config";
import ScoreModal from "./ScoreModal";
import TextTable from "./TextTable";

class TextContainer extends React.Component {
  static propTypes = {
    players: PropTypes.array.isRequired,
    contracts: PropTypes.array.isRequired,
    rounds: PropTypes.array.isRequired,
    gameId: PropTypes.string.isRequired
  };
  state = {
    selectedRound: null,
    showConfirmDelete: false
  };

  handleShowModal = (round, roundId) => this.setState({selectedRound: {round, roundId}});
  handleCloseModal = () => this.setState({selectedRound: null, showConfirmDelete: false});

  onHideConfirmDelete = () => this.setState({showConfirmDelete: false});
  onShowConfirmDelete = () => this.setState({showConfirmDelete: true});
  onDeleteRoundConfirmed = () => {
    const {gameId} = this.props;
    const {selectedRound} = this.state;
    const self = this;
    firestore.collection(config.firebase.gamesCollectionName).doc(gameId).update({
      rounds: fieldValue.arrayRemove(selectedRound.round)
    }).then(() => self.handleCloseModal())
      .catch(error => console.log(error)); // TODO error handling on failing to delete
  };

  render() {
    const {selectedRound, showConfirmDelete} = this.state;
    const {players, rounds, contracts} = this.props;

    return (
      <div className="score-container">
        <Confirm open={showConfirmDelete}
                 onCancel={this.onHideConfirmDelete}
                 onConfirm={this.onDeleteRoundConfirmed}
                 cancelButton={texts.game.score.cancelButton}
                 confirmButton={texts.game.score.deleteRound}
                 header={texts.game.score.deleteRound}
                 content={texts.game.score.confirmDelete(selectedRound && selectedRound.roundId)}/>
        <ScoreModal onDelete={this.onShowConfirmDelete}
                    onClose={this.handleCloseModal}
                    contracts={contracts}
                    players={players}
                    round={selectedRound}/>
        <TextTable rounds={rounds} players={players} onShowModal={this.handleShowModal}/>
      </div>
    );
  }
}

export default TextContainer;
