import React, {Component} from 'react';
import Router from "./Router";
import {Container, Segment} from "semantic-ui-react";
import Footer from "./common/Footer";
import withFirebaseSignIn from "./common/withFirebaseSignIn";


class App extends Component {
  render() {
    return (
      <Container className="content">
        <Segment>
          <div id="content">
            <Router/>
          </div>
          <Footer/>
        </Segment>
      </Container>
    );
  }
}

export default withFirebaseSignIn(App);
