import React from 'react';
import PropTypes from 'prop-types';
import {Form, Message} from "semantic-ui-react";
import _ from 'lodash';
import moment from "moment";
import {calculateScores} from "../../utils/scoreCalculator";
import {fieldValue, firestore} from "../../config/firebaseConfig";
import TeamSelector from "./playtab/TeamSelector";
import {texts} from "../../config/texts";
import {config} from "../../config/config";

class PlayTab extends React.Component {
  static propTypes = {
    players: PropTypes.array.isRequired,
    contracts: PropTypes.array.isRequired,
    gameId: PropTypes.string.isRequired,
    onRoundSubmitted: PropTypes.func.isRequired
  };

  state = {
    contract: -1,
    contractWinner: -1,
    playsWon: "",
    team1: [],
    team2: [],
    isSubmitting: false,
    submitError: null
  };

  constructor(props) {
    super(props);

    this.teamSelectorRef = null;
    this.setTeamSelectorRef = elm => this.teamSelectorRef = elm;
  }

  isFormValid = () => {
    const {contract, contractWinner, team1, team2, playsWon} = this.state;

    const playsWonInt = parseInt(playsWon, 10);

    return contract !== -1 &&
      contractWinner !== -1 &&
      !isNaN(playsWonInt) && playsWonInt >= 0 && playsWonInt <= 13 && // playsWon must be a number and between 0 and 13
      team1.length > 0 &&
      team2.length > 0 &&
      team1.length + team2.length === 4;
  };

  handleChange = (e, {name, value}) => this.setState({[name]: value});
  handleOnTeamChanged = (name, value) => this.setState({[name]: value});
  handleContractChange = (e, {name, value}) => {
    this.handleChange(e, {name, value});
    this.teamSelectorRef.handelContractChanged(name, value);
  };

  handleSubmit = () => {
    const {contract, contractWinner, team1, team2, playsWon} = this.state;
    const {gameId, onRoundSubmitted, contracts} = this.props;
    const playsWonAsInt = parseInt(playsWon, 10);
    const obj = {
      "contract": contract,
      "wonContract": contractWinner,
      "team1": team1,
      "team2": team2,
      "playsWon": playsWonAsInt,
      "timestamp": moment().format(),
      "scoreDistribution": calculateScores(contracts, contract, team1, team2, playsWonAsInt)
    };
    this.setState({isSubmitting: true});
    try {
      const self = this;
      const fbRef = firestore.collection(config.firebase.gamesCollectionName).doc(gameId);

      fbRef.update({
        rounds: fieldValue.arrayUnion(obj)
      })
        .then(() => {
          self.setState({
            contract: -1,
            contractWinner: -1,
            playsWon: "",
            team1: [],
            team2: [],
            isSubmitting: false,
            submitError: null
          });
          onRoundSubmitted();
        })
        .catch(error => this.setState({errorMessage: error.toString(), isSubmitting: false}));
    } catch (err) {
      this.setState({submitError: err.toString(), isSubmitting: false});
    }
  };

  render() {
    const {players, contracts} = this.props;
    const {isSubmitting, submitError, contractWinner} = this.state;

    const contractOptions = contracts.filter(c => c.isSelected).map(c => {
      return {key: `contract-${c.id}`, text: c.name, value: c.id}
    });

    const playerOptions = _.map(players, p => {
      return {key: `player-${p.id}`, text: p.name, value: p.id};
    });

    const isSubmitPossible = this.isFormValid();

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit} loading={isSubmitting} error={!!submitError}>
          <Form.Group widths="equal">
            <Form.Select name="contract" label={texts.game.play.contract} search options={contractOptions}
                         value={this.state.contract}
                         onChange={this.handleChange}/>
            <Form.Select name="contractWinner" label={texts.game.play.contractWinner} options={playerOptions}
                         value={this.state.contractWinner} onChange={this.handleContractChange}/>
          </Form.Group>
          <TeamSelector players={players} onTeamChanged={this.handleOnTeamChanged}
                        contractWinner={contractWinner} ref={this.setTeamSelectorRef}/>
          <Form.Input name="playsWon" min="0" max="13" label={texts.game.play.playsWon} type="number"
                      value={this.state.playsWon}
                      onChange={this.handleChange}/>

          <Message error header={texts.common.error} content={submitError}/>
          <Form.Button content={texts.game.play.save} positive fluid disabled={!isSubmitPossible}/>
        </Form>
      </React.Fragment>
    );
  }
}

export default PlayTab;
