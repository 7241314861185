export const texts = {
  app: {
    name: "Whist Score",
    author: "Thomas Heine Rasmussen",
  },
  firebase: {
    errorConnecting: "Der kunne ikke oprettes forbindelse til Firebase",
  },
  common: {
    add: "Tilføj",
    close: "Luk",
    error: "Fejl",
    fetchingData: "Henter data...",
    errorOccurred: "Der skete en fejl",
    loading: "Loading...",
    version: "Version",
  },
  home: {
    bullet1: "Alle kan se status på et spil og få live opdateringer hver gang en ny runde er tilføjet",
    bullet2: "Alle kan hjælpe med at registrere point for hver runde",
    bullet3: "Der er simple statistikker, så det er nemt at se hvor god (eller dårlig!) hver spiller er",
    subHeader: "er en Web App der hjælper med at holde styr på pointene i Whist",
    loadGame: "Indlæs spil",
    loadGameDesc: "Indlæs og fortsæt et eksisterende spil",
    loadGamePlaceholder: "Indtast spil nummer",
    startGame: "Opret nyt spil",
    startGameDesc: "Hold styr på pointene i et nyt spil Whist",
    startGameCmd: "Opret spil",
    recentGamesNoFound: "Ingen seneste spil funder",
    recentGamesHeader: "Seneste spil",
  },
  create: {
    creatingGame: "Opretter spil",
    createGame: "Opret spil",
    errorCreating: "Fejl under oprettelse af spil",
    addPlayer: "Tilføj spiller",
    namePlaceholder: "Indtast navn",
    playersTitle: "Spillere",
    description: (minPlayers) => `Tilføj spillere. Det kræver mindst ${minPlayers} spillere for at starte.`,
    contracts: "Mulige meldinger",
    contract: "Melding",
    score: "Point",
    canChoose: "Kan vælges"
  },
  game: {
    listener: {
      tooOldGameHeader: "Kunne ikke indlæse spil",
      tooOldGame: (gameId) =>  `Spil med nummer ${gameId} kan ikke indlæses, da det er oprettet i en for gammel version.`,
      errorRetrievingGame: "Fejl under hentning af spil",
      errorRetrievingGameText: (gameId) => `Kunne ikke hente spil med nummer '${gameId}'. Det er højst sandsynligt fordi spillet ikke findes.`,
      loading: "Henter spil",
    },
    menu: {
      play: "Spil",
      score: "Score",
      stats: "Stats",
      info: "Info"
    },
    play: {
      contract: "Melding",
      contractWinner: "Hvem fik meldingen",
      playsWon: "Antal stik",
      save: "Gem",
      teamWhoWonContract: "Hold der fik meldingen",
      opponents: "Modstandere",
    },
    score: {
      cancelButton: "Anuller",
      deleteRound: "Slet runde",
      confirmDelete: (roundId) => `Er du sikker på du vil slette runde ${roundId}?`,
      round: "Runde",
      total: "Total",
      didTeam1Win: "Gik den hjem",
      showAsText: "Vis som text",
      showAsGraph: "Vis som graf",
      showInGraph: "Vis i graf"
    },
    stats: {
      totalScore: "Point",
      contractWins: "Antal meldinger",
      wonContractWins: "Antal vundne meldinger",
      totalPlays: "Antal spil",
      winPrPlay: "Meldingsprocent",
      selfPartnerCount: "Antal selvmakker",
      noloContracts: "Antal nolo meldinger",
      mostPointsInRound: "Flest point i en runde",
      leastPointsInRound: "Størst minus i en runde",
      mostPlayedContract: "Melding der spilles flest gange",
      mostWonContract: "Melding der vindes flest gange",
      mostLostContract: "Melding der tabes flest gange",
      bestPartner: "Bedste makker",
      worstPartner: "Værste makker",
    },
    info: {
      gameNumber: "Spil nummer",
      created: "Oprettet",
      createdIn: "Oprettet i",
      contracts: "Meldinger",
      rankingContractSubHeader: "(+ angiver med benævnelse)",
      point: "Point",
      ranking: "Rangering",
      contractAddons: "Benævnelser",
      vip: "Vip: Trumffarven fastsættes ved at vende (vippe) kortene i katten (Hvis der stoppes ved en joker spilles der uden trumf)",
      good: "Gode: Trumfspil i klør",
      half: "Halve: Makker bestemmer trumfarven (Skal være forskellig fra kuløren af makker-esset)",
      strong: "Stærke: Trumfspil i spar",
      sans: "Sans: Uden trumf",
    }
  }
};
