import React from 'react';
import {Header, Icon, List} from "semantic-ui-react";
import {auth, firestore} from "../../config/firebaseConfig";
import {Link} from "react-router-dom";
import _ from 'lodash';
import {texts} from "../../config/texts";
import {config} from "../../config/config";

class RecentGames extends React.Component {
  state = {
    recentGames: null,
    errorMessage: null
  };

  componentDidMount() {
    firestore.collection(config.firebase.historyCollectionName).doc(auth.currentUser.uid).get()
      .then(docs => {
        if (!docs.exists) {
          this.setState({recentGames: []});
        } else {
          this.setState({recentGames: docs.data().games});
        }
      })
      .catch(error => this.setState({errorMessage: error.message}));
  }

  render() {
    const {recentGames, errorMessage} = this.state;

    let content;
    if (errorMessage != null) {
      content = <p>{texts.common.errorOccurred}: {errorMessage}</p>
    } else if (recentGames == null) {
      content = <p><Icon loading name="spinner"/>{texts.common.fetchingData}</p>;
    } else if (recentGames.length === 0) {
      content = <p>{texts.home.recentGamesNoFound}</p>
    } else {
      const sortedGames = _.orderBy(recentGames, ['lastLoaded'], ['desc']);
      const listContent = sortedGames.map(r => <List.Item as="li" key={r.gameId}><Link to={`/game/${r.gameId}`}>{r.gameId}</Link></List.Item>);
      content = <List as="ul">{listContent}</List>
    }

    return (
      <React.Fragment>
        <Header as="h2" dividing>{texts.home.recentGamesHeader}</Header>
        {content}
      </React.Fragment>
    )
  }
}

export default RecentGames;
