import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import HomePage from "./home/HomePage";
import CreateGamePage from "./create/CreateGamePage";
import GamePage from "./game/GamePage";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage}/>
        <Route exact path="/create-game" component={CreateGamePage}/>
        <Route path="/game/:gameId" component={GamePage}/>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
