import React from "react";
import {Button, Header, Icon, Input} from "semantic-ui-react";
import PropTypes from "prop-types";
import _ from 'lodash';
import {texts} from "../../config/texts";

class AddPlayer extends React.Component {
  static propTypes = {
    onAddClick: PropTypes.func.isRequired
  };
  state = {name: ""};

  onInputChange = (e, value) => this.setState({name: value.value});

  handleOnAddClick = () => {
    const {name} = this.state;
    const nameVal = name.trim();
    if (nameVal.length > 0) {
      this.setState({name: ""});
      this.props.onAddClick(_.upperFirst(_.toLower(nameVal)));
    }
  };

  getInputAction = () => {
    const {name} = this.state;
    const disableAction = !name.trim().length;
    return (
      <Button primary disabled={disableAction} onClick={this.handleOnAddClick}>
        <Icon className="add user"/> {texts.common.add}
      </Button>
    );
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleOnAddClick();
    }
  };

  render() {
    const {name} = this.state;

    const inputAction = this.getInputAction();
    return (
      <React.Fragment>
        <Header dividing>{texts.create.addPlayer}</Header>
        <Input
          onKeyPress={this.onKeyPress}
          placeholder={texts.create.namePlaceholder}
          value={name}
          onChange={this.onInputChange}
          action={inputAction}
        />
      </React.Fragment>
    );
  }
}

export default AddPlayer;
