import React from 'react';
import PropTypes from 'prop-types';
import ModalTable from "./ModalTable";
import {Button, Icon, Modal} from "semantic-ui-react";
import {texts} from "../../../config/texts";

const ScoreModal = ({round, players, onDelete, onClose, contracts}) => {
  return (
    <Modal onClose={onClose} open={round != null} centered={false} size="mini">
      <Modal.Header>{texts.game.score.round} {round && round.roundId}</Modal.Header>
      <Modal.Content scrolling>
        {round &&
        <ModalTable players={players} round={round.round} roundId={round.roundId} contracts={contracts}/>}
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' onClick={onDelete}>
          <Icon name='trash alternate outline'/> {texts.game.score.deleteRound}
        </Button>
        <Button onClick={onClose}>
          <Icon name="cancel"/> {texts.common.close}
        </Button>
      </Modal.Actions>
    </Modal>
  )
};

ScoreModal.propTypes = {
  round: PropTypes.object,
  players: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ScoreModal;
