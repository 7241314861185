import _ from 'lodash';

export function calculateScores(contracts, contractId, team1, team2, playsWon) {
  const contract = _.find(contracts, {id: contractId});

  if (contract.isSpecial === true) {
    return calculateSpecialGame(contract, team1, team2, playsWon)
  } else {
    return calculateNormalPlay(contract, team1, team2, playsWon);
  }
}

function calculateNormalPlay(contract, team1, team2, playsWon) {
  const didTeam1Win = playsWon >= contract.requiredPlays;
  // Formula:
  // Winning play: Y+x*Y
  // Losing play: -Y+x*Y
  // Y = score
  // x = plays from contract
  const extraScore = didTeam1Win ? contract.score : -contract.score; // = The first Y value

  // Use the Math.abs as we manually set the plus/minus for each team
  const playsFromContract = playsWon - contract.requiredPlays;
  const playerScore = Math.abs(extraScore + (playsFromContract * contract.score));

  if (team1.length === team2.length) {
    return _.concat(
      mapTeam(team1, didTeam1Win ? playerScore : -playerScore),
      mapTeam(team2, didTeam1Win ? -playerScore : playerScore)
    );
  } else {
    const team1Score = playerScore * team2.length;
    const team2Score = playerScore;
    return _.concat(
      mapTeam(team1, didTeam1Win ? team1Score : -team1Score),
      mapTeam(team2, didTeam1Win ? -team2Score : team2Score)
    );
  }
}

function calculateSpecialGame(contract, team1, team2, playsWon) {
  const didTeam1Win = playsWon <= contract.requiredPlays;
  const playerScore = contract.score;

  return _.concat(
    mapTeam(team1, didTeam1Win ? (playerScore * team2.length) : -(playerScore * team2.length)),
    mapTeam(team2, didTeam1Win ? -playerScore : playerScore)
  );
}

function mapTeam(team, score) {
  return _.map(team, t => {
    return {"playerId": t, "points": score};
  });
}
