import {Header, Icon, List} from "semantic-ui-react";
import React from "react";
import * as PropTypes from "prop-types";
import {texts} from "../../config/texts";

const PlayerListItem = ({player, onRemoveClick}) => {
  return (
    <List.Item>
      <Icon className="user" size="large"/>
      <List.Content verticalAlign='middle'>
        {player} <Icon link className="delete" color="red" onClick={() => onRemoveClick(player)}/>
      </List.Content>
    </List.Item>
  );
};

PlayerListItem.propTypes = {
  onRemoveClick: PropTypes.func.isRequired,
  player: PropTypes.string.isRequired
};

const PlayerList = ({players, onRemoveClick}) => {
  const playerCount = players.length;
  const playerRows = players.map((player, index) => <PlayerListItem player={player} key={index}
                                                                    onRemoveClick={onRemoveClick}/>);
  return (
    <React.Fragment>
      <Header dividing>{texts.create.playersTitle} ({playerCount})</Header>
      <List divided verticalAlign='middle'>
        {playerRows}
      </List>
    </React.Fragment>
  );
};

PlayerList.propTypes = {
  players: PropTypes.array.isRequired,
  onRemoveClick: PropTypes.func.isRequired
};

export default PlayerList
