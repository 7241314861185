import React from 'react';
import {Button, Header, Icon, Segment} from "semantic-ui-react";
import {texts} from "../../config/texts";

const NewGame = ({history}) => {
  return (
    <Segment>
      <Header as="h3" dividing>{texts.home.startGame}</Header>
      <p>{texts.home.startGameDesc}</p>
      <Button primary onClick={() => history.push("/create-game")}>
        <Icon className="add"/>
        {texts.home.startGameCmd}
      </Button>
    </Segment>
  );
};

export default NewGame;
