import React from "react";
import {Divider, List} from "semantic-ui-react";
import {texts} from "../../config/texts";
import {config} from "../../config/config";

const Footer = () => {
  const now = new Date().getFullYear();
  return (
    <div id="footer">
      <Divider/>
      <List horizontal divided>
        <List.Item>
          &copy;{now} {texts.app.author}
        </List.Item>
        <List.Item>
          Version: {config.uiVersion}{config.isTestMode && "-dev"}
        </List.Item>
      </List>
    </div>
  );
};

export default Footer;
