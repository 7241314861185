import React from 'react';
import {texts} from "../../../config/texts";
import {Button, Icon, Modal} from "semantic-ui-react";
import PropTypes from 'prop-types';

class ContractPointModal extends React.Component {
  static propTypes = {
    contracts: PropTypes.array.isRequired
  };
  state = {modalOpen: false};

  handleOpen = () => this.setState({modalOpen: true});

  handleClose = () => this.setState({modalOpen: false});

  render() {
    const {contracts} = this.props;
    const contractRows = contracts.filter(c => c.isSelected).map(p =>
      <tr key={`info-contract-${p.id}`}>
        <td>{p.name}</td>
        <td>{p.score}</td>
      </tr>
    );

    return (
      <Modal trigger={<Button onClick={this.handleOpen}>{texts.game.info.point}</Button>}
             centered={false} size="mini" open={this.state.modalOpen} onClose={this.handleClose}>
        <Modal.Header>{texts.game.info.point}</Modal.Header>
        <Modal.Content scrolling>
          <table className="contract-table full-width">
            <thead>
            <tr>
              <th>{texts.create.contract}</th>
              <th>{texts.create.score}</th>
            </tr>
            </thead>
            <tbody>
            {contractRows}
            </tbody>
          </table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose}>
            <Icon name="cancel"/> {texts.common.close}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ContractPointModal;
