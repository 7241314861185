import _ from "lodash";

export function getPlayerScore(playerId, scoreDistribution) {
  const score = _.find(scoreDistribution, {playerId: playerId});
  return score === undefined ? 0 : score.points;
}

export function getPlayerTotalScore(players, rounds) {
  return players.map(p => {
    return {
      playerId: p.id,
      points: _.sumBy(rounds, r => {
        return getPlayerScore(p.id, r.scoreDistribution);
      })
    }
  });
}

export function getNumberOfContractWins(players, rounds) {
  return players.map(p => {
    return {
      playerId: p.id,
      points: _.sumBy(rounds, r => {
        const wonContract = r.wonContract === p.id;
        return wonContract ? 1 : 0;
      })
    }
  });
}

export function getNumberOfPlays(players, rounds) {
  return players.map(p => {
    return {
      playerId: p.id,
      points: _.sumBy(rounds, r => {
        const playedRound = _.includes(r.team1, p.id) || _.includes(r.team2, p.id);
        return playedRound ? 1 : 0;
      })
    }
  });
}

export function getNumberOfContractWinsPrPlay(players, rounds) {
  const contractWins = getNumberOfContractWins(players, rounds);
  const numberOfPlaysArr = getNumberOfPlays(players, rounds);

  return contractWins.map(c => {
    const numberOfContractWins = c.points;
    const numberOfPlays = _.find(numberOfPlaysArr, {playerId: c.playerId}).points;
    let winPercent = 0;
    if (numberOfPlays > 0 && numberOfContractWins > 0) {
      winPercent = (numberOfContractWins / numberOfPlays) * 100
    }
    return {
      playerId: c.playerId,
      points: winPercent.toFixed(0) + "%"
    }
  });
}

export function getNumberOfSelfPartner(contracts, players, rounds) {
  return players.map(p => {
    return {
      playerId: p.id,
      points: _.sumBy(rounds, r => {
        const contract = _.find(contracts, {id: r.contract});
        const selfPartner = !contract.isSpecial && r.wonContract === p.id && r.team1.length === 1;
        return selfPartner ? 1 : 0;
      })
    }
  });
}

export function getNumberOfWinningContractWins(contracts, players, rounds) {
  return players.map(p => {
    return {
      playerId: p.id,
      points: _.sumBy(rounds, r => {
        const contract = _.find(contracts, {id: r.contract});
        const wonContract = r.wonContract === p.id;
        const didTeam1Win = contract.isSpecial ? r.playsWon <= contract.requiredPlays : r.playsWon >= contract.requiredPlays;
        return wonContract && didTeam1Win ? 1 : 0;
      })
    }
  });
}

export function getNumberOfNoloContracts(contracts, players, rounds) {
  return players.map(p => {
    return {
      playerId: p.id,
      points: _.sumBy(rounds, r => {
        const contract = _.find(contracts, {id: r.contract});
        const noloContractWinner = contract.isSpecial && r.wonContract === p.id && r.team1.length === 1;
        return noloContractWinner ? 1 : 0;
      })
    }
  });
}

export function getPlayerWithHighestScore(players, rounds) {
  let maxScore = {points: 0, playerIds: []};
  let minScore = {points: 0, playerIds: []};
  rounds.forEach(r => {
    r.scoreDistribution.forEach(s => {
      if (maxScore.playerIds.length === 0) {
        maxScore.points = s.points;
        maxScore.playerIds.push(s.playerId);
      } else if (maxScore.points === s.points) {
        maxScore.playerIds.push(s.playerId);
      } else if (s.points > maxScore.points) {
        maxScore.points = s.points;
        maxScore.playerIds = [s.playerId,];
      }

      if (minScore.playerIds.length === 0) {
        minScore.points = s.points;
        minScore.playerIds.push(s.playerId);
      } else if (minScore.points === s.points) {
        minScore.playerIds.push(s.playerId);
      } else if (s.points < minScore.points) {
        minScore.points = s.points;
        minScore.playerIds = [s.playerId,];
      }
    });
  });

  if (rounds.length === 0) {
    return {minScore: "-", maxScore: "-"};
  }

  const maxName = _.uniq(maxScore.playerIds).map(id => players.find(p => p.id === id).name).join(", ");
  const minName = _.uniq(minScore.playerIds).map(id => players.find(p => p.id === id).name).join(", ");
  return {
    maxScore: `${maxName} (${maxScore.points})`,
    minScore: `${minName} (${minScore.points})`
  };
}

export function getContractStats(rounds, contracts) {
  const stat = [];
  rounds.forEach(r => {
    let existing = _.find(stat, {id: r.contract});
    if (existing === undefined) {
      existing = {id: r.contract, count: 0, wins: 0, losses: 0};
      stat.push(existing);
    }

    existing.count += 1;
    const contract = _.find(contracts, {id: r.contract});
    const wonRound = contract.isSpecial ? r.playsWon <= contract.requiredPlays : r.playsWon >= contract.requiredPlays;
    if (wonRound) {
      existing.wins += 1
    } else {
      existing.losses += 1;
    }
  });

  if (rounds.length === 0) {
    return {mostPlayed: "-", mostWon: "-", mostLoss: "-"};
  }

  const obj = {mostPlayed: "-", mostWon: "-", mostLoss: "-"};
  const mostPlayedCount = _.maxBy(stat, "count").count;
  if (mostPlayedCount > 0) {
    const name = stat.filter(p => p.count === mostPlayedCount).map(s => contracts.find(c => c.id === s.id).shortName).join(", ");
    obj.mostPlayed = `${name} (${mostPlayedCount})`
  }
  const mostWonCount = _.maxBy(stat, "wins").wins;
  if (mostWonCount > 0) {
    const name = stat.filter(p => p.wins === mostWonCount).map(s => contracts.find(c => c.id === s.id).shortName).join(", ");
    obj.mostWon = `${name} (${mostWonCount})`;
  }
  const mostLossCount = _.maxBy(stat, "losses").losses;
  if (mostLossCount > 0) {
    const name = stat.filter(p => p.losses === mostLossCount).map(s => contracts.find(c => c.id === s.id).shortName).join(", ");
    obj.mostLoss = `${name} (${mostLossCount})`;
  }

  return obj;
}

export function getPartnerScores(players, rounds, contracts) {
  const stat = [];
  rounds.filter(r => r.team1.length === 2).forEach(r => {
    const contract = _.find(contracts, {id: r.contract});
    const wonRound = contract.isSpecial ? r.playsWon <= contract.requiredPlays : r.playsWon >= contract.requiredPlays;

    // Count player not getting contract
    const playerId = r.team1.filter(p => p !== r.wonContract)[0];
    let existing = _.find(stat, {id: playerId});
    if (existing === undefined) {
      existing = {id: playerId, wins: 0, losses: 0};
      stat.push(existing);
    }
    if (wonRound) {
      existing.wins += 1;
    } else {
      existing.losses += 1;
    }

    // Count for the opponents
    r.team2.forEach(p => {
      let existing = _.find(stat, {id: p});
      if (existing === undefined) {
        existing = {id: p, wins: 0, losses: 0};
        stat.push(existing);
      }
      // Team1 won the round, so are bad partners
      if (wonRound) {
        existing.losses += 0.5;
      } else {
        existing.wins += 0.5;
      }
    });
  });

  if (rounds.length === 0 || stat.length === 0) {
    return {bestPartner: "-", worstPartner: "-"};
  }
  const obj = {bestPartner: "-", worstPartner: "-"};

  const worstPartnerCount = _.maxBy(stat, "losses").losses;
  if (worstPartnerCount > 0) {
    obj.worstPartner = stat.filter(s => s.losses === worstPartnerCount).map(s => players.find(p => p.id === s.id).name).join(", ");
  }

  // Remove the worst players, as the can not also be the best!
  const updatedStatList = stat.filter(s => s.losses !== worstPartnerCount);

  const bestPartnerCount = _.maxBy(updatedStatList, "wins").wins;
  if (bestPartnerCount > 0) {
    obj.bestPartner = updatedStatList.filter(s => s.wins === bestPartnerCount).map(s => players.find(p => p.id === s.id).name).join(", ");
  }
  return obj;
}
