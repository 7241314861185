import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyC4bt_iXZB2LbU4DBqAQFnbWjPnX4Mw4tc",
  authDomain: "whist-score.firebaseapp.com",
  databaseURL: "https://whist-score.firebaseio.com",
  projectId: "whist-score",
  storageBucket: "whist-score.appspot.com",
  messagingSenderId: "650775849800"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
firestore.settings({timestampsInSnapshots: true});

const fieldValue = firebase.firestore.FieldValue;

export {
  auth,
  firestore,
  fieldValue
};
