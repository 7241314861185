import React from 'react';
import PropTypes from 'prop-types';
import {Table} from "semantic-ui-react";
import _ from "lodash";
import {texts} from "../../../config/texts";

const getPlayerName = (playerId, players) => {
  const player = _.find(players, {id: playerId});
  return player === undefined ? "[error]" : player.name;
};

const ModalTable = ({round, roundId, players, contracts}) => {
  const contract = _.find(contracts, {id: round.contract});
  if (contract === undefined) {
    return <p>Invalid data!</p>;
  }
  const wonRound = contract.isSpecial ? round.playsWon <= contract.requiredPlays : round.playsWon >= contract.requiredPlays;
  return <Table basic='very' striped>
    <Table.Body>
      <Table.Row>
        <Table.Cell>{texts.game.play.contract}</Table.Cell>
        <Table.Cell>{contract.name}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>{texts.game.play.contractWinner}</Table.Cell>
        <Table.Cell>{getPlayerName(round.wonContract, players)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>{texts.game.play.teamWhoWonContract}</Table.Cell>
        <Table.Cell>{round.team1.map(p => getPlayerName(p, players)).join(', ')}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>{texts.game.play.opponents}</Table.Cell>
        <Table.Cell>{round.team2.map(p => getPlayerName(p, players)).join(', ')}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>{texts.game.play.playsWon}</Table.Cell>
        <Table.Cell>{round.playsWon}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>{texts.game.score.didTeam1Win}</Table.Cell>
        <Table.Cell>{wonRound ? "Ja" : "Nej"}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>;
};

ModalTable.propTypes = {
  round: PropTypes.object.isRequired,
  roundId: PropTypes.number.isRequired,
  players: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired
};

export default ModalTable;
