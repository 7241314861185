import React from 'react';
import PropTypes from 'prop-types';
import {Header, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {texts} from "../../config/texts";

const SiteHeader = ({backClickRoute}) => {
  return (
    <Header as="h1" dividing>
      {backClickRoute &&
      <div style={{display: "inline-block"}}>
        <Link to={backClickRoute}>
          <Icon className="chevron left"/>
        </Link>
        &nbsp;
      </div>
      }
      <Header.Content>{texts.app.name}</Header.Content>
    </Header>
  );
};

SiteHeader.propTypes = {
  backClickRoute: PropTypes.string
};

export default SiteHeader;
