import React from 'react';
import PropTypes from 'prop-types';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import _ from "lodash";
import {texts} from "../../../config/texts";

const colors = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"];
const playerKey = "player";

const buildData = (players, rounds) => {
  const sortedRounds = _.orderBy(rounds, ['timestamp']);

  const accumulatedScores = {};
  const newData = sortedRounds.map((round, index) => {
    const response = {name: texts.game.score.round + " " + (index + 1)};
    round.scoreDistribution.forEach(r => {
      const key = playerKey + r.playerId;
      if (key in accumulatedScores) {
        accumulatedScores[key] = accumulatedScores[key] + r.points;
      } else {
        accumulatedScores[key] = r.points;
      }
      response[key] = accumulatedScores[key];
    });
    return response
  });
  const zeroValue = {name: "Start"};
  players.forEach(p => zeroValue[playerKey + p.id] = 0);
  newData.unshift(zeroValue);

  return newData;
};

const buildLines = (players) => {
  const sortedPlayers = _.orderBy(players, ['id']);

  return sortedPlayers.map(p => {
    const color = p.id > colors.length ? "#000000" : colors[p.id];
    return <Line key={p.id} type="monotone" name={p.name} dataKey={`${playerKey}${p.id}`} stroke={color}
                 connectNulls={true}/>
  });
};


const Graph = ({rounds, players}) => {
  const data = buildData(players, rounds);
  const lines = buildLines(players);
  const graphWidth = Math.max(450, data.length * 23);

  return (
    <div style={{overflowX: "auto", overflowY: "hidden"}}>
      <ResponsiveContainer height={450} width={"100%"} minWidth={graphWidth}>
        <LineChart data={data} margin={{top: 5, right: 30, left: 5, bottom: 5}}>
          <XAxis dataKey="name"/>
          <YAxis/>
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip/>
          <Legend verticalAlign="top" height={36}/>
          {lines}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
};

Graph.propTypes = {
  rounds: PropTypes.array.isRequired,
  players: PropTypes.array.isRequired
};

export default Graph;
