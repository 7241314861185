import React from 'react';
import PropTypes from 'prop-types';
import {Form} from "semantic-ui-react";
import _ from "lodash";
import {texts} from "../../../config/texts";

// TODO should probably look into refactoring this class

class TeamSelector extends React.Component {
  static propTypes = {
    players: PropTypes.array.isRequired,
    contractWinner: PropTypes.number.isRequired,
    onTeamChanged: PropTypes.func.isRequired
  };
  state = {
    team1: [],
    team2: []
  };

  handelContractChanged = (team, playerId) => {
    const {team1, team2} = this.state;
    const {contractWinner} = this.props;
    _.remove(team1, v => v === playerId || v === contractWinner);
    _.remove(team2, v => v === playerId || v === contractWinner);
    team1.push(playerId);
    this.setState({team1, team2});
    this.props.onTeamChanged("team1", team1);
    this.props.onTeamChanged("team2", team2);
  };

  handleCheckboxState = (e, {name, value, checked}) => {
    const members = name === "team1" ? this.state.team1 : this.state.team2;

    if (checked === true) {
      members.push(value);
    } else {
      _.remove(members, v => v === value);
    }

    this.setState({[name]: members});
    this.props.onTeamChanged(name, members);
  };

  playerCheckboxes = (players, keyPrefix, name, team, otherMembers, contractWonByPlayerId) => {
    return _.map(players, p => {
      const selectedInOtherTeam = _.findIndex(otherMembers, (v) => v === p.id) === -1;
      const wonAnnouncement = p.id === contractWonByPlayerId;
      let checked = (name === "team1" && wonAnnouncement) || (_.findIndex(team, v => v === p.id) !== -1);
      if (wonAnnouncement && !selectedInOtherTeam) {
        checked = false;
      }
      let disabled = !selectedInOtherTeam || wonAnnouncement;

      const totalPlayersSelected = team.length + otherMembers.length;
      if (totalPlayersSelected === 4 && !checked) {
        disabled = true;
      }

      return <Form.Checkbox
        key={`player-checkbox-${keyPrefix}-${p.id}`}
        disabled={disabled}
        label={p.name}
        value={p.id}
        name={name}
        checked={checked}
        onChange={this.handleCheckboxState}/>
    });
  };

  render() {
    const {team1, team2} = this.state;
    const {contractWinner, players} = this.props;

    const playerCheckboxesA = this.playerCheckboxes(players, "a", "team1", team1, team2, contractWinner);
    const playerCheckboxesB = this.playerCheckboxes(players, "b", "team2", team2, team1, contractWinner);

    return <React.Fragment>
      <div className="field">
        <label>{texts.game.play.teamWhoWonContract}</label>
        <Form.Group inline>
          {playerCheckboxesA}
        </Form.Group>
      </div>
      <div className="field">
        <label>{texts.game.play.opponents}</label>
        <Form.Group inline>
          {playerCheckboxesB}
        </Form.Group>
      </div>
    </React.Fragment>;
  }
}

export default TeamSelector;
