import React from 'react';
import SiteHeader from "../common/SiteHeader";
import {Button, Dimmer, Divider, Icon, Loader, Message} from "semantic-ui-react";
import PlayerList from "./PlayerList";
import AddPlayer from "./AddPlayer";
import PropTypes from "prop-types";
import {firestore} from "../../config/firebaseConfig";
import moment from 'moment';
import _ from 'lodash';
import {config} from "../../config/config";
import {texts} from "../../config/texts";
import EditContract from "./EditContract";

class CreateGamePage extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };
  state = {
    players: [],
    minPlayers: config.requiredPlayers,
    isCreatingGame: false,
    contracts: [],
    errorMessage: null
  };

  handleOnAddClick = (newPlayer) => {
    const currentPlayers = this.state.players;

    // Only add new player, if he does not already exist
    if (_.find(currentPlayers, (player) => player.toLowerCase() === newPlayer.toLowerCase()) === undefined) {
      currentPlayers.push(newPlayer);
      this.setState({players: currentPlayers});
    }
  };

  handleOnRemoveClick = (player) => {
    const players = this.state.players;
    _.remove(players, (p) => p === player);
    this.setState({players: players})
  };

  handleChangeContract = (contracts) => {
    this.setState({contracts: contracts});
  };

  handleCreateGame = () => {
    // this.setState({isCreatingGame: true, errorMessage: null});
    const {players, contracts} = this.state;
    const gameId = _.padStart(_.random(1, 99999), 5, "0");
    const gameData = {
      players: _.map(players, (val, i) => {
        return {id: i, name: val};
      }),
      info: {
        created: moment().format(),
        gameId: gameId,
        uiVersion: config.uiVersion
      },
      contracts: contracts,
      rounds: []
    };

    try {
      firestore.collection(config.firebase.gamesCollectionName).doc(gameData.info.gameId).set(gameData)
        .then(() => this.props.history.push(`/game/${gameData.info.gameId}`))
        .catch(error => this.setState({errorMessage: error.toString()}));
    } catch (err) {
      this.setState({errorMessage: err.toString()});
    }
  };

  render() {
    const {minPlayers, players, isCreatingGame, errorMessage, contracts} = this.state;
    const selectedContracts = _.sumBy(contracts, c => c.isSelected ? 1 : 0);
    const disableCreateButton = players.length < minPlayers || selectedContracts === 0;
    return (
      <React.Fragment>
        <SiteHeader backClickRoute="/"/>
        <Dimmer active={isCreatingGame && !errorMessage}><Loader
          indeterminate>{texts.create.creatingGame}</Loader></Dimmer>
        <p>{texts.create.description(minPlayers)}</p>
        <PlayerList players={players} onRemoveClick={this.handleOnRemoveClick}/>
        <AddPlayer onAddClick={this.handleOnAddClick}/>
        <Divider/>
        <EditContract onContractChange={this.handleChangeContract}/>
        <Divider/>
        <Button primary disabled={disableCreateButton} onClick={this.handleCreateGame}>
          <Icon className="plus"/> {texts.create.createGame}
        </Button>
        {errorMessage &&
        <Message negative><Message.Header>{texts.create.errorCreating}</Message.Header><p>{errorMessage}</p></Message>}
      </React.Fragment>
    );
  }
}

export default CreateGamePage;
