import React from 'react';
import PropTypes from 'prop-types';
import SiteHeader from "../common/SiteHeader";
import withGameListener from "./GameListener";
import {Tab} from "semantic-ui-react";
import PlayTab from "./PlayTab";
import ScoreTab from "./ScoreTab";
import StatsTab from "./StatsTab";
import InfoTab from "./InfoTab";
import {texts} from "../../config/texts";

class GamePage extends React.Component {
  static propTypes = {
    gameData: PropTypes.object.isRequired,
    gameId: PropTypes.string.isRequired
  };
  state = {activeIndex: 0};

  handleOnRoundSubmitted = () => this.setState({activeIndex: 1});
  handleTabChange = (e, {activeIndex}) => this.setState({activeIndex});

  render() {
    const {gameId, gameData} = this.props;
    const {activeIndex} = this.state;
    const panes = [
      {
        menuItem: texts.game.menu.play,
        render: () => <Tab.Pane as="div"><PlayTab players={gameData.players} gameId={gameId}
                                                  contracts={gameData.contracts}
                                                  onRoundSubmitted={this.handleOnRoundSubmitted}/></Tab.Pane>
      },
      {
        menuItem: texts.game.menu.score,
        render: () => <Tab.Pane as="div"><ScoreTab rounds={gameData.rounds} gameId={gameId}
                                                   contracts={gameData.contracts}
                                                   players={gameData.players}/></Tab.Pane>
      },
      {
        menuItem: texts.game.menu.stats,
        render: () => <Tab.Pane as="div"><StatsTab players={gameData.players} rounds={gameData.rounds}
                                                   contracts={gameData.contracts}/></Tab.Pane>
      },
      {
        menuItem: texts.game.menu.info,
        render: () => <Tab.Pane as="div"><InfoTab info={gameData.info} contracts={gameData.contracts}/></Tab.Pane>
      },
    ];
    return (
      <div id="game">
        <SiteHeader backClickRoute="/"/>
        <Tab panes={panes} activeIndex={activeIndex} onTabChange={this.handleTabChange}/>
      </div>
    );
  };
}

export default withGameListener(GamePage);
