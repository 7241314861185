import React from 'react';
import {Message} from "semantic-ui-react";
import {texts} from "../../config/texts";

const PageDescription = () => {
  const bulletList = [texts.home.bullet1, texts.home.bullet2, texts.home.bullet3];

  return (
    <React.Fragment>
      <p><strong>{texts.app.name}</strong> {texts.home.subHeader}</p>
      <Message list={bulletList}/>
    </React.Fragment>
  );
};

export default PageDescription;
