import React from 'react';
import {Checkbox, Form, Header} from "semantic-ui-react";
import {texts} from "../../config/texts";
import PropTypes from 'prop-types';

class EditContract extends React.Component {
  static propTypes = {
    onContractChange: PropTypes.func.isRequired
  };
  state = {
    contracts: [
      {id: 0, score: 2, requiredPlays: 8, isSpecial: false, isSelected: false, name: "8", shortName: "8"},
      {id: 1, score: 4, requiredPlays: 8, isSpecial: false, isSelected: false, name: "8 med benævnelse" , shortName: "8+"},
      {id: 2, score: 4, requiredPlays: 9, isSpecial: false, isSelected: true, name: "9", shortName: "9"},
      {id: 3, score: 8, requiredPlays: 9, isSpecial: false, isSelected: true, name: "9 med benævnelse", shortName: "9+"},
      {id: 4, score: 8, requiredPlays: 10, isSpecial: false, isSelected: true, name: "10", shortName: "10"},
      {id: 5, score: 16, requiredPlays: 10, isSpecial: false, isSelected: true, name: "10 med benævnelse", shortName: "10+"},
      {id: 6, score: 16, requiredPlays: 11, isSpecial: false, isSelected: true, name: "11", shortName: "11"},
      {id: 7, score: 32, requiredPlays: 11, isSpecial: false, isSelected: true, name: "11 med benævnelse", shortName: "11+"},
      {id: 8, score: 32, requiredPlays: 12, isSpecial: false, isSelected: true, name: "12", shortName: "12"},
      {id: 9, score: 64, requiredPlays: 12, isSpecial: false, isSelected: true, name: "12 med benævnelse", shortName: "12+"},
      {id: 10, score: 64, requiredPlays: 13, isSpecial: false, isSelected: true, name: "13", shortName: "13"},
      {id: 11, score: 128, requiredPlays: 13, isSpecial: false, isSelected: true, name: "13 med benævnelse", shortName: "13+"},
      {id: 12, score: 10, requiredPlays: 1, isSpecial: true, isSelected: true, name: "Sol", shortName: "Sol"},
      {id: 13, score: 20, requiredPlays: 0, isSpecial: true, isSelected: true, name: "Ren sol", shortName: "Ren sol"},
      {id: 14, score: 40, requiredPlays: 0, isSpecial: true, isSelected: true, name: "Halv bordlægger", shortName: "Halv bordlægger"},
      {id: 15, score: 80, requiredPlays: 0, isSpecial: true, isSelected: true, name: "Bordlægger", shortName: "Bordlægger"},
      {id: 16, score: 160, requiredPlays: 0, isSpecial: true, isSelected: true, name: "Bordlægger med snak", shortName: "Bordlægger med snak"},
    ]
  };

  componentDidMount() {
    this.props.onContractChange(this.state.contracts);
  }

  toggleContract = (elm) => {
    // Yes, I know this is ugly and we should be immutable, but we are not!
    // Not sure if there are any bad side effects to this
    elm.isSelected = !elm.isSelected;
    this.setState({contracts: this.state.contracts});
    this.props.onContractChange(this.state.contracts);
  };

  handleContractPoint = (elm, value) => {
    // Yes, I know this is ugly and we should be immutable, but we are not!
    // Not sure if there are any bad side effects to this
    const parsedValue = parseInt(value, 10);
    if(isNaN(parsedValue)) {
      elm.score = "";
    } else {
      elm.score = parsedValue < 0 ? 0 : parsedValue;
    }
    this.setState({contracts: this.state.contracts});
    this.props.onContractChange(this.state.contracts);
  };

  render() {
    const {contracts} = this.state;
    const contractRows = contracts.map(p =>
      <tr key={`possible-contract-${p.id}`}>
        <td>{p.name}</td>
        <td>
          <Form.Input type="number"
                      min="0"
                      value={p.score}
                      onChange={(e, {name, value}) => this.handleContractPoint(p, value)}/>
        </td>
        <td>
          <Checkbox data-contract={p.id}
                    toggle
                    fitted
                    checked={p.isSelected}
                    onChange={() => this.toggleContract(p)}/>
        </td>
      </tr>
    );

    return (
      <React.Fragment>
        <Header dividing>{texts.create.contracts}</Header>
        <table className="contract-table">
          <thead>
          <tr>
            <th>{texts.create.contract}</th>
            <th>{texts.create.score}</th>
            <th>{texts.create.canChoose}</th>
          </tr>
          </thead>
          <tbody>
          {contractRows}
          </tbody>
        </table>
      </React.Fragment>
    )
  }
}

export default EditContract;
