import React from 'react';
import {texts} from "../../../config/texts";
import {Button, Icon, List, Modal} from "semantic-ui-react";

class RankingModal extends React.Component {
  state = {modalOpen: false};

  handleOpen = () => this.setState({modalOpen: true});

  handleClose = () => this.setState({modalOpen: false});

  render() {
    return (
      <Modal trigger={<Button onClick={this.handleOpen}>{texts.game.info.ranking}</Button>}
             centered={false} size="mini" open={this.state.modalOpen} onClose={this.handleClose}>
        <Modal.Header>{texts.game.info.ranking}</Modal.Header>
        <Modal.Content scrolling>
          <p className="info-list-header"><strong>{texts.game.info.contracts}</strong> {texts.game.info.rankingContractSubHeader}</p>
          <List bulleted className="info-list">
            <List.Item>8</List.Item>
            <List.Item>8+</List.Item>
            <List.Item>9</List.Item>
            <List.Item>Sol</List.Item>
            <List.Item>9+</List.Item>
            <List.Item>10</List.Item>
            <List.Item>Ren sol</List.Item>
            <List.Item>10+</List.Item>
            <List.Item>11</List.Item>
            <List.Item>Halv bordlægger</List.Item>
            <List.Item>11+</List.Item>
            <List.Item>12</List.Item>
            <List.Item>Bordlægger</List.Item>
            <List.Item>12+</List.Item>
            <List.Item>13</List.Item>
            <List.Item>Bordlægger med snak</List.Item>
            <List.Item>13+</List.Item>
          </List>
          <p className="info-list-header"><strong>{texts.game.info.contractAddons}</strong></p>
          <List bulleted className="info-list">
            <List.Item>{texts.game.info.vip}</List.Item>
            <List.Item>{texts.game.info.good}</List.Item>
            <List.Item>{texts.game.info.half}</List.Item>
            <List.Item>{texts.game.info.strong}</List.Item>
            <List.Item>{texts.game.info.sans}</List.Item>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose}>
            <Icon name="cancel"/> {texts.common.close}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default RankingModal;
