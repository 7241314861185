import React, {Component} from 'react';
import {auth} from '../../config/firebaseConfig';
import {Dimmer, Header, Loader} from "semantic-ui-react";
import {texts} from "../../config/texts";

export default function withFirebaseSignIn(WrappedComponent) {
  return class extends Component {
    state = {
      isSignedIn: false,
      errorMessage: null
    };

    componentDidMount() {
      auth.signInAnonymously()
        .then(() => {
          this.setState({isSignedIn: true});
        }).catch(error => {
        this.setState({errorMessage: error.message});
      });
    }

    render() {
      const {isSignedIn, errorMessage} = this.state;

      if (isSignedIn) {
        return <WrappedComponent/>;
      }

      if (errorMessage != null) {
        return (
          <div>
            <Header as="h2">{texts.firebase.errorConnecting}</Header>
            <p>{errorMessage}</p>
          </div>
        )
      }

      return <Dimmer active><Loader>{texts.common.loading}</Loader></Dimmer>;
    }
  };
}
