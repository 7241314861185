import React, {Component} from 'react';
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer/Dimmer";
import {Header, Loader} from "semantic-ui-react";
import SiteHeader from "../common/SiteHeader";
import {auth, firestore} from "../../config/firebaseConfig";
import _ from 'lodash';
import moment from "moment";
import {texts} from "../../config/texts";
import {config} from "../../config/config";

export default function withGameListener(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      const gameIdFromUrl = this.props.match.params.gameId;
      this.state = {
        gameId: gameIdFromUrl,
        isFetchingData: true,
        data: null,
        rounds: null
      };
    }

    updateRecentGames = (gameId) => {
      const obj = {gameId: gameId, lastLoaded: moment().valueOf()};

      const ref = firestore.collection(config.firebase.historyCollectionName).doc(auth.currentUser.uid);
      ref.get()
        .then(doc => {
          if (!doc.exists) {
            ref.set({games: [obj,]}).then();
          } else {
            const existingRecentGames = doc.data().games;
            const existingRecentGame = _.find(existingRecentGames, {'gameId': gameId});
            if (existingRecentGame !== undefined) {
              existingRecentGame.lastLoaded = obj.lastLoaded;
            } else {
              existingRecentGames.push(obj);
            }

            ref.update({games: existingRecentGames}).then();
          }
        })
        // Currently we just log when this happens, not sure what we should do instead
        .catch(error => console.log(error));
    };

    componentDidMount() {
      const {gameId} = this.state;
      const self = this;
      let hasUpdatedRecentGames = false;
      this.unsubscribe = firestore.collection(config.firebase.gamesCollectionName)
        .doc(gameId)
        .onSnapshot(doc => {
          if (!doc.exists) {
            self.setState({isFetchingData: false, data: null});
            return;
          }

          if (!hasUpdatedRecentGames) {
            hasUpdatedRecentGames = true;
            self.updateRecentGames(gameId);
          }

          self.setState({isFetchingData: false, data: doc.data()});
        })
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    render() {
      const {gameId, data, isFetchingData} = this.state;

      if (isFetchingData) {
        return <Dimmer active><Loader indeterminate>{texts.game.listener.loading}</Loader></Dimmer>;
      } else if (data == null) {
        return (
          <React.Fragment>
            <SiteHeader backClickRoute="/"/>
            <Header as="h2">{texts.game.listener.errorRetrievingGame}</Header>
            <p>{texts.game.listener.errorRetrievingGameText(gameId)}</p>
          </React.Fragment>
        )
      } else if (parseFloat(data.info.uiVersion) < parseFloat(config.minCompatibilityVersion)) {
        return (
          <React.Fragment>
            <SiteHeader backClickRoute="/"/>
            <Header as="h2">{texts.game.listener.tooOldGameHeader}</Header>
            <p>{texts.game.listener.tooOldGame(gameId)}</p>
          </React.Fragment>
        )
      }

      return <WrappedComponent gameId={gameId} gameData={data}/>
    }
  }
}
