import React from 'react';
import PropTypes from 'prop-types';
import {
  getContractStats,
  getNumberOfContractWins,
  getNumberOfContractWinsPrPlay,
  getNumberOfNoloContracts,
  getNumberOfPlays,
  getNumberOfSelfPartner,
  getNumberOfWinningContractWins, getPartnerScores,
  getPlayerScore,
  getPlayerTotalScore,
  getPlayerWithHighestScore
} from "../../utils/statHelper";
import {renderPlayerThs} from "../../utils/tableRenderHelper";
import {texts} from "../../config/texts";
import {List} from "semantic-ui-react";

const renderColumns = (players, roundId, scoreDist) => {
  return players.map(p => {
    const score = getPlayerScore(p.id, scoreDist);
    return <td key={`col-stat-${roundId}-player-${p.id}`}>{score}</td>;
  });
};

const renderRow = (players, value, text, rowId) => {
  return (
    <tr key={`row-stat-${rowId}`}>
      <td><strong>{text}</strong></td>
      {renderColumns(players, rowId, value)}
    </tr>
  );
};

const StatsTab = ({players, rounds, contracts}) => {
  const playerTotalScore = getPlayerTotalScore(players, rounds);
  const playerThs = renderPlayerThs(players, playerTotalScore);

  // Stats table
  const totalScoreRow = renderRow(players, playerTotalScore, texts.game.stats.totalScore, "point");
  const contractWinnerRow = renderRow(players, getNumberOfContractWins(players, rounds), texts.game.stats.contractWins, "contract-wins");
  const numberOfPlaysRow = renderRow(players, getNumberOfPlays(players, rounds), texts.game.stats.totalPlays, "total-plays");
  const winPrPlayRow = renderRow(players, getNumberOfContractWinsPrPlay(players, rounds), texts.game.stats.winPrPlay, "contract-wins-pr-play");
  const selfPartnerRow = renderRow(players, getNumberOfSelfPartner(contracts, players, rounds), texts.game.stats.selfPartnerCount, "self-partner");
  const winningContractWinnerRow = renderRow(players, getNumberOfWinningContractWins(contracts, players, rounds), texts.game.stats.wonContractWins, "contract-win-wins");
  const noloContracts = renderRow(players, getNumberOfNoloContracts(contracts, players, rounds), texts.game.stats.noloContracts, "nolo-contracts");

  // Single stats
  const scores = getPlayerWithHighestScore(players, rounds);
  const contractStats = getContractStats(rounds, contracts);
  const partnerStats = getPartnerScores(players, rounds, contracts);

  return (
    <div>
      <div className="stat-container">
        <table className="stat-table">
          <thead>
          <tr>
            <th/>
            {playerThs}
          </tr>
          </thead>
          <tbody>
          {totalScoreRow}
          {contractWinnerRow}
          {winningContractWinnerRow}
          {numberOfPlaysRow}
          {winPrPlayRow}
          {selfPartnerRow}
          {noloContracts}
          </tbody>
        </table>
      </div>
      <List divided relaxed>
        <List.Item>{texts.game.stats.bestPartner}: {partnerStats.bestPartner}</List.Item>
        <List.Item>{texts.game.stats.worstPartner}: {partnerStats.worstPartner}</List.Item>
        <List.Item>{texts.game.stats.mostPointsInRound}: {scores.maxScore}</List.Item>
        <List.Item>{texts.game.stats.leastPointsInRound}: {scores.minScore}</List.Item>
        <List.Item>{texts.game.stats.mostPlayedContract}: {contractStats.mostPlayed}</List.Item>
        <List.Item>{texts.game.stats.mostWonContract}: {contractStats.mostWon}</List.Item>
        <List.Item>{texts.game.stats.mostLostContract}: {contractStats.mostLoss}</List.Item>
      </List>
    </div>
  )
};

StatsTab.propTypes = {
  players: PropTypes.array.isRequired,
  rounds: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired
};

export default StatsTab;
