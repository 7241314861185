import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {texts} from "../../config/texts";
import {config} from "../../config/config";
import ContractPointModal from "./infotab/ContractPointModal";
import RankingModal from "./infotab/RankingModal";

const InfoTab = ({info, contracts}) => {
  return (
    <React.Fragment>
      <strong>{texts.game.info.gameNumber}:</strong>
      <p>{info.gameId}</p>

      <strong>{texts.game.info.contracts}:</strong>
      <br/>
      <ContractPointModal contracts={contracts}/>
      <RankingModal/>
      <br/>
      <br/>

      <strong>{texts.game.info.created}:</strong>
      <p>{moment(info.created).format(config.dateFormats.dateAndTime)}</p>

      <strong>{texts.game.info.createdIn}:</strong>
      <p>{texts.common.version} {info.uiVersion}</p>
    </React.Fragment>
  )
};

InfoTab.propTypes = {
  info: PropTypes.object.isRequired,
  contracts: PropTypes.array.isRequired
};

export default InfoTab;
