const getCollectionPrefix = () => {
  if (process.env.REACT_APP_COLLECTION_PREFIX !== undefined) {
    return process.env.REACT_APP_COLLECTION_PREFIX;
  }
  return "";
};

export const config = {
  uiVersion: "0.9",
  minCompatibilityVersion: "0.6",
  requiredPlayers: 4,
  dateFormats: {
    dateAndTime: "DD-MM-YYYY HH:mm:ss"
  },
  firebase: {
    historyCollectionName: getCollectionPrefix() + "history",
    gamesCollectionName: getCollectionPrefix() + "games"
  },
  isTestMode: getCollectionPrefix() !== ""
};
