import React from 'react';
import PropTypes from 'prop-types';
import {Divider} from "semantic-ui-react";
import Graph from "./Graph";
import GraphPlayerSelect from "./GraphPlayerSelect";

class GraphContainer extends React.Component {
  static propTypes = {
    players: PropTypes.array.isRequired,
    rounds: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {selectedPlayers: props.players}
  };

  onSelectedPlayersChange = (selectedPlayers) => {
    this.setState({selectedPlayers});
  };

  render() {
    const {rounds, players} = this.props;
    const {selectedPlayers} = this.state;
    return (
      <React.Fragment>
        <GraphPlayerSelect selectedPlayers={selectedPlayers} possiblePlayers={players}
                           onSelectedPlayersChange={this.onSelectedPlayersChange}/>
        <Divider/>
        <Graph rounds={rounds} players={selectedPlayers}/>
      </React.Fragment>
    )
  }
}

export default GraphContainer;
