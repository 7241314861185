import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from "semantic-ui-react";
import * as _ from "lodash";
import {texts} from "../../../config/texts";

const getPlayerCheckboxes = (selectedPlayers, possiblePlayers, onChange) => {
  return possiblePlayers.map(p => {
    const isChecked = _.findIndex(selectedPlayers, sp => sp.id === p.id) !== -1;

    return <Checkbox key={p.id} label={p.name} style={{marginRight: 10}} checked={isChecked} value={p.id} onChange={onChange} />
  });
};

const GraphPlayerSelect = ({selectedPlayers, possiblePlayers, onSelectedPlayersChange}) => {
  const onCheckboxChange = (e, {name, value, checked}) => {
    if(!checked) {
      const newSelected = [...selectedPlayers];
      _.remove(newSelected, p => p.id === value);
      onSelectedPlayersChange(newSelected)
    } else {
      const playerToAdd = _.find(possiblePlayers, p => p.id === value);
      onSelectedPlayersChange([...selectedPlayers, playerToAdd]);
    }
  };

  const checkboxes = getPlayerCheckboxes(selectedPlayers, possiblePlayers, onCheckboxChange);

  return (
    <React.Fragment>
      <span style={{marginRight: 10}}>{texts.game.score.showInGraph}:</span>{checkboxes}
    </React.Fragment>
  )
};

GraphPlayerSelect.propTypes = {
  selectedPlayers: PropTypes.array.isRequired,
  possiblePlayers: PropTypes.array.isRequired,
  onSelectedPlayersChange: PropTypes.func.isRequired
};

export default GraphPlayerSelect;
