import React from 'react';
import PropTypes from 'prop-types';
import {Button, Header, Icon, Input, Segment} from "semantic-ui-react";
import {texts} from "../../config/texts";

class LoadGame extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };
  state = {
    gameId: ""
  };

  onInputChange = (e, value) => this.setState({gameId: value.value});

  getInputAction = () => {
    const disableAction = !this.state.gameId.length;
    return (
      <Button primary disabled={disableAction} onClick={this.handleOnJoinClick}>
        {texts.home.loadGame} <Icon className="arrow right"/>
      </Button>);
  };

  handleOnJoinClick = () => {
    const {history} = this.props;
    history.push(`/game/${this.state.gameId}`);
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleOnJoinClick();
    }
  };

  render() {
    const inputAction = this.getInputAction();
    return (
      <Segment>
        <Header as="h3" dividing>{texts.home.loadGame}</Header>
        <p>{texts.home.loadGameDesc}</p>
        <Input fluid
               onKeyPress={this.onKeyPress}
               type="number"
               placeholder={texts.home.loadGamePlaceholder}
               action={inputAction}
               onChange={this.onInputChange}/>
      </Segment>
    );
  }
}

export default LoadGame;
