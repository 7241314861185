import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {getPlayerScore, getPlayerTotalScore} from "../../../utils/statHelper";
import {renderPlayerThs} from "../../../utils/tableRenderHelper";
import {texts} from "../../../config/texts";

const renderColumns = (players, roundId, scoreDist) => {
  return players.map(p => {
    const score = getPlayerScore(p.id, scoreDist);
    return (
      <td key={`col-round-${roundId}-player-${p.id}`}>
        {score === 0 ? <span className="no-score">{score}</span> : score}
      </td>
    );
  });
};

const renderRow = (players, roundId, round, roundClick) => {
  return (
    <tr key={`row-round-${roundId}`}>
      <td><span className="score-round" onClick={() => roundClick(round, roundId)}>{roundId}</span></td>
      {renderColumns(players, roundId, round.scoreDistribution)}
    </tr>
  );
};

const renderTotalRow = (players, scores) => {
  return (
    <tr key={`row-round-total`}>
      <td><strong>{texts.game.score.total}</strong></td>
      {renderColumns(players, 'total', scores)}
    </tr>
  );
};

const TextTable = ({players, rounds, onShowModal}) => {
  const sortedPlayers = _.orderBy(players, ['id']);
  const sortedRounds = _.orderBy(rounds, ['timestamp']);
  const scoreRows = sortedRounds.map((round, index) => renderRow(sortedPlayers, index + 1, round, onShowModal)).reverse();

  const playerTotalScores = getPlayerTotalScore(players, rounds);
  const totalRow = renderTotalRow(players, playerTotalScores);
  const playerThs = renderPlayerThs(players, playerTotalScores);

  return (
    <table className="score-table">
      <thead>
      <tr>
        <th>{texts.game.score.round}</th>
        {playerThs}
      </tr>
      </thead>
      <tbody>
      {totalRow}
      {scoreRows}
      </tbody>
    </table>
  );
};

TextTable.propTypes = {
  rounds: PropTypes.array.isRequired,
  players: PropTypes.array.isRequired,
  onShowModal: PropTypes.func.isRequired
};

export default TextTable;
