import React from 'react';
import PropTypes from 'prop-types';
import TextContainer from "./scoretab/TextContainer";
import {Button, Divider} from "semantic-ui-react";
import GraphContainer from "./scoretab/GraphContainer";
import {texts} from "../../config/texts";

class ScoreTab extends React.Component {
  static propTypes = {
    players: PropTypes.array.isRequired,
    contracts: PropTypes.array.isRequired,
    rounds: PropTypes.array.isRequired,
    gameId: PropTypes.string.isRequired
  };
  state = {selectedIndex: 0};

  render() {
    const {selectedIndex} = this.state;
    const {players, rounds, contracts, gameId} = this.props;
    return (
      <React.Fragment>
        <Button.Group size="mini" basic>
          <Button toggle
                  active={selectedIndex === 0}
                  onClick={() => this.state.selectedIndex !== 0 && this.setState({selectedIndex: 0})}>
            {texts.game.score.showAsText}
          </Button>
          <Button toggle
                  active={selectedIndex === 1}
                  onClick={() => this.state.selectedIndex !== 1 && this.setState({selectedIndex: 1})}>
            {texts.game.score.showAsGraph}
          </Button>
        </Button.Group>
        <Divider style={{marginTop: 5}}/>
        {selectedIndex === 0 &&
        <TextContainer players={players} contracts={contracts} rounds={rounds} gameId={gameId}/>}
        {selectedIndex === 1 && <GraphContainer players={players} rounds={rounds}/>}
      </React.Fragment>
    );
  };
}

export default ScoreTab;
